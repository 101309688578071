import tw from "twin.macro";
import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/About.js";
import Features1 from "components/features/use-cases.js";

import Pricing1 from "components/pricing/ThreePlans.js";
import Features2 from "components/features/features.js";
import Testimonial1 from "components/testimonials/SimplePrimaryBackground.js";
import GetStarted from "components/cta/GetStarted.js";
import ExamSupported from "components/exasmsSupported/examSupported";
import Footer from "components/footers/SimpleFiveColumn.js";
import Header from "./components/headers/light";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function App() {
  // const StyledDiv = tw.div`-mb-10`;
  return (
    <Router>
      <Switch>
        <Route path="/">
          <div
            style={{
              margin: "0 auto",
              // paddingTop: "20px",
              paddingRight: "20px",
              paddingLeft: "20px",
              overflow: "hidden",
            }}
          >
            <Header />
            <Hero />
            <Features2 />
            {/* <Testimonial /> */}
            <ExamSupported />
            <Features1 />
            {/* <Testimonial1 /> */}
            <div style={{ marginTop: "-90px" }}>
              <Pricing1 />
            </div>

            <div style={{ marginTop: "-90px" }}>
              <Features />
            </div>
            <div
              style={{
                marginTop: "-102px",
              }}
            >
              <GetStarted />
            </div>
            <div style={{ marginTop: "-120px" }}>
              <Footer />
            </div>
          </div>
        </Route>
      </Switch>
    </Router>
  );
}
