import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import OCR from "../../images/ocr.png";
import SecondImage from "../../images/feat-2.png";
import ThirdImage from "../../images/feat-3.png";
import FourthImage from "../../images/feat-4.png";
import FifthImage from "../../images/feat-5.png"
import {
  SectionHeading,
  Subheading as Subheading,
} from "components/misc/Headings.js";
import { height } from "@mui/system";
const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`my-20 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-96 md:h-96 bg-auto bg-center mx-4 sm:mx-8 md:mx-4 `,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default ({
  subheading = null
}) => {
  const cards = [
    {
      imageSrc: OCR,
      subtitle: "",
      title: "Are you using Google Forms for conducting online tests?",
      description:
        "Our tool is easier, faster, provides a better interface and out-of-the-box analytics to both the students and the educators. You can even upload screenshots or images of handwritten question and use our app to automatically create a digital version of the assessment.",
      url: "https://timerse.com",
    },

    {
      imageSrc: SecondImage,
      subtitle: "",
      title: "Having a hard time tracking and evaluating student progress?",
      description:
        "Do you give regular homeworks, practice material to your students but have a hard time tracking and evaluating them? Use our tool to upload all your homeworks in one place and enjoy comprehensive analytics and progress tracking for all your students.",
      url: "https://timerse.com",
    },

    {
      imageSrc: ThirdImage,
      subtitle: "",
      title: "Enable your students to practice for competitive exams.",
      description: "Want to prepare your students for the D-day? Create assessments in the exact same format as of the exam and prepare your students by giving them the experiece of a real exam.",
      url: "https://timerse.com",
    },
    {
      imageSrc: FourthImage,
      subtitle: "",
      title: "Can’t find a platform to organize your own test series?",
      description:
        "You can user our platform to conduct your own test series, olympiads and scholarship tests accessible to everyone around the world.",
      url: "https://timerse.com",
    },
    {
      imageSrc: FifthImage,
      subtitle: "",
      title: "Struggling to make a test that meets all your requirements?",
      description:
        "Our tools gives you the flexibility to add multiple sections with all kinds of questions types such as \
        MCQ, Multiple Correct MCQ, Comprehension, and Numerical along with customizable marking schemes for each question.",
      url: "https://timerse.com",
    },
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>One App for All Your Needs</HeadingTitle>
          {/* <HeadingDescription>
            Here are some of the most popular events in New York City curated by professionals.
          </HeadingDescription> */}
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <img src={card.imageSrc} style={{ width: "100%", maxWidth: "420px", height: "auto" }} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {/* <Link href={card.url}>See Event Details</Link> */}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
