import React from "react";
import ReactDOM from "react-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as SvgDecoratorBlob } from "images/svg-decorator-blob-6.svg";
import TickImage from "images/tick-mark.svg";
import {
  Container as ContainerBase,
  ContentWithPaddingXl as ContentBase,
} from "components/misc/Layouts.js";

const HeaderContainer = tw.div`w-full flex flex-col items-center mb-10`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const Container = tw(ContainerBase)`bg-blue-400 text-gray-100 -mx-8 px-8`;
const ContentWithPaddingXl = tw(
  ContentBase
)`relative z-10 mx-auto px-0 py-10 sm:px-6 md:px-8 lg:px-12 xl:px-24 sm:py-20 flex flex-col max-w-screen-xl`;
const PlansContainer = tw.div`flex justify-center flex-col lg:flex-row items-center lg:items-stretch relative`;
const Plan = styled.div`
  ${tw`w-full max-w-sm mt-16 lg:mr-8 lg:last:mr-0 text-center px-8 mb-8 mx-8 rounded-lg shadow relative mt-2 pt-0 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }

  ${(props) =>
    props.featured &&
    css`
      background: rgb(100,21,255);
      background: linear-gradient(135deg, rgba(100,21,255,1) 0%, rgba(128,64,252,1) 100%);
background: rgb(85,60,154);
background: linear-gradient(135deg, rgba(85,60,154,1) 0%, rgba(128,90,213,1) 100%);
background: rgb(76,81,191);
background: linear-gradient(135deg, rgba(76,81,191,1) 0%, rgba(102,126,234,1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
      .planHighlight {
        ${tw`hidden`}
      }
      .duration {
        ${tw`text-gray-200!`}
      }
      ${PlanFeatures} {
        ${tw`border-indigo-500`}
      }
      .feature:not(.mainFeature) {
        ${tw`text-gray-300!`}
      }
      ${BuyNowButton} {
        ${tw`bg-gray-100 text-primary-500 hocus:bg-gray-300 hocus:text-primary-800`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-4`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-3 border-t-2 border-b-2 flex-1`}
  .feature {
    ${tw`first:mt-0 font-medium text-left`}
    &:not(.mainFeature) {
      ${tw`text-gray-600`}
    }
  }
  .mainFeature {
    ${tw`text-xl font-bold tracking-wide`}
  }
`;

const PlanAction = tw.div`px-4 sm:px-8 xl:px-16 py-3`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const WhiteBackgroundOverlay = tw.div`absolute inset-x-0 bottom-0 h-1/6 lg:h-1/3 bg-white z-0`;

const DecoratorBlob = styled(SvgDecoratorBlob)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-1/2 translate-y-1/2`}
`;

function Mailto({ email, subject, body, ...props }) {
  return (
    <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
      {props.children}
    </a>
  );
}

export default ({
  subheading = null,
  heading = "Pricing",
  description = null,
  plans = null,
  primaryButtonText = "Contact Us",
  primaryLinkUrl = "mailto:quriate@gmail.com"
}) => {
  const defaultPlans = [
    {
      name: "Free",
      price: null,
      duration: null,
      mainFeature: null,
      imageSrc: TickImage,
      features: [
        "Unlimited Tests",
        "Unlimited Questions Per Test",
        "Upto 100 Students Per Test",
        "Support for MCQ, MMCQ and Numerical Questions",
        "Custom marking for every question",
        "Detailed Analytics For both students & educators",
      ],
      buttonText: "Start Using",
    },
    {
      name: "Pro",
      price: null,
      duration: null,
      mainFeature: null,
      imageSrc: TickImage,
      features: [
        "Everything in Free Plan",
        "Automatic PDF/Handwritten Notes/PageMaker To Online Assessment Tool",
        "Access to NTA-formatted tests",
        "Batch Management Dashboard",
        "Private Sharing of Test to a Batch",
        "Support for Comprehension-based Questions",
        "Advanced Analytics & Personalized Recommendations",
        "Priority Support",
      ],
      featured: true,
      buttonText: "Contact Us",
    },
    // {
    //   name: "Enterprise",
    //   price: "$57.99",
    //   duration: "Monthly",
    //   mainFeature: "Suited for Big Companies",
    //   features: [
    //     "90 Templates",
    //     "9 Landing Pages",
    //     "37 Internal Pages",
    //     "Personal Assistance",
    //   ],
    // },
  ];

  if (!plans) plans = defaultPlans;

  const highlightGradientsCss = [
    css`
      background: rgb(56, 178, 172);
      background: linear-gradient(
        115deg,
        rgba(56, 178, 172, 1) 0%,
        rgba(129, 230, 217, 1) 100%
      );
    `,
    css`
      background: rgb(56, 178, 172);
      background-image: linear-gradient(
        115deg,
        #6415ff,
        #7431ff,
        #8244ff,
        #8e56ff,
        #9a66ff
      );
    `,
    css`
      background: rgb(245, 101, 101);
      background: linear-gradient(
        115deg,
        rgba(245, 101, 101, 1) 0%,
        rgba(254, 178, 178, 1) 100%
      );
    `,
  ];

  return (
    <Container id="Pricing" style={{ zIndex: 1 }}>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              {!plan.featured && (
                <div
                  className="planHighlight"
                  css={
                    highlightGradientsCss[index % highlightGradientsCss.length]
                  }
                />
              )}
              <PlanHeader>
                <span className="name">{plan.name}</span>
                <span className="price">{plan.price}</span>
                <span className="duration">{plan.duration}</span>
              </PlanHeader>
              <PlanFeatures>
                {(plan.mainFeature == null) ? <div></div> :
                  <div style={{ alignItems: "left", display: "flex", justifyContent: "left", marginTop: "10px" }} >
                    <img src={plan.imageSrc} style={{ height: "1.8rem", width: "1.8rem", verticalAlign: 'middle', paddingRight: "0.2rem" }} />
                    <span className="feature mainFeature">{plan.mainFeature}</span>
                  </div>
                }
                {plan.features.map((feature, index) => (
                  <div style={{ alignItems: "left", display: "flex", justifyContent: "left", marginTop: "10px" }} >
                    <img src={plan.imageSrc} style={{ height: "1.5rem", width: "1.5rem", verticalAlign: 'middle', paddingRight: "0.2rem" }} />
                    <span key={index} className="feature">
                      {feature}
                    </span>
                  </div>
                ))}
              </PlanFeatures>
              <PlanAction>
                <BuyNowButton
                  css={!plan.featured && highlightGradientsCss[index]}
                  target={`_blank`} href={primaryLinkUrl}
                  style={{ borderRadius: "2rem" }}
                >
                  <Mailto email="quriate@gmail.com" subject="Interested in buying Pro Plan" body="Hi! I'd like to schedule a meeting to discuss the pricing plans.">
                    {plan.buttonText}
                  </Mailto>
                </BuyNowButton>
              </PlanAction>
            </Plan>
          ))}
          <DecoratorBlob />
        </PlansContainer>
      </ContentWithPaddingXl>
      <WhiteBackgroundOverlay />
    </Container>
  );
};
