import React from "react";
import tw from "twin.macro";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { ContentWithPaddingXl, Container } from "components/misc/Layouts";

import { Grid, Chip } from "@mui/material";
const PrimaryBackgroundContainer = tw.div`py-20 lg:py-24 bg-blue-500 rounded-lg relative`;
const Row = tw.div`px-8 max-w-screen-lg mx-auto flex items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`;

const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`;
const TextContainer = tw(ColumnContainer)`w-screen`;
const Text = tw.h5`text-gray-100 text-2xl sm:text-3xl font-bold`;

const LinksContainer = tw(
  ColumnContainer
)`flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row `;

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const SecondaryLink = tw(
  Link
)`text-white bg-gray-700 border-2 border-white hover:bg-gray-100 hover:text-blue-500 hover:border-blue-500 block`;
const PrimaryLink = SecondaryLink;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-blue-500 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-blue-500 opacity-50`;
export default function Ok({
  text = "Multiple Exams format",
  primaryLinkText = "Register",
  primaryLinkUrl = "http://timerse.com",
  secondaryLinkText = "Contact Us",
  secondaryLinkUrl = "http://google.com",
  pushDownFooter = true,
}) {
  const exams = [
    "JEE Main",
    "JEE Advanced",
    "NEET",
    "Olympiad",
    "GATE",
    "InPhO",
  ];
  const nextExams = ["Olympiad", "GATE", "InPhO"];
  const ok = exams.map((elem) => {
    return (
      <Grid
        item
        xs={12}
        md={4}
        sm={12}
        style={{ backgroundColor: "", margin: "" }}
      >
        {/* <Chip style={{padding:"20px 50px"}} label={elem} variant="outlined" /> */}
        <PrimaryLink>{elem}</PrimaryLink>
      </Grid>
    );
  });

  return (
    <div id="ExamsSupported">
      <div css={tw`text-center -mx-8 bg-blue-900`}>
        <div css={tw`text-white font-bold pt-10 mx-2 `} style={{ fontSize: "40px" , margin:"0 12px"}}>
          Multiple Exam Formats Supported
        </div>
        <Grid
          container
          // spacing={`100px`}
          // direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} style={{ margin: "70px" }}>
            <Grid container spacing={`40px`}>
              {ok}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
