import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import {
  Container as ContainerBase,
  ContentWithPaddingXl as ContentBase,
} from "components/misc/Layouts.js";

import defaultCardImage from "images/shield-icon.svg";
import CreateIconImage from "images/create-icon1.svg"
import AnalyticsIconImage from "images/analytics-icon1.svg"
import ShareIconImage from "images/share-icon1.svg"

const Container = tw(ContainerBase)`bg-blue-400 text-gray-100 -mx-8 px-8 pb-20`;
const ContentWithPaddingXl = tw(
  ContentBase
)`relative z-10 mx-auto px-0 py-10 sm:px-6 md:px-8 lg:px-12 xl:px-24 sm:py-20 flex flex-col max-w-screen-xl`;
const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-gray-300 text-center`;

const PlansContainer = tw.div`mt-16 flex flex-col items-center lg:flex-row lg:items-stretch lg:justify-between text-gray-900 font-medium`;

const PlanHeader = styled.div`
${tw`flex flex-wrap flex-col sm:flex-row justify-center items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 pb-5 mb-6`}
.imageContainer {
  ${tw`border text-center rounded-full p-8 flex-shrink-0 md:items-center mx-5 mb-5`}
  img {
    ${tw`w-8 h-8`}
  }
}
.nameAndFeaturedContainer {
  ${tw`flex flex-wrap flex-col sm:flex-row justify-center items-center text-center`}
}
.name {
  ${tw`lg:text-lg xl:text-sm font-bold uppercase tracking-wider mr-3 justify-center text-center`}
}
.description {
  ${tw`mt-8 font-medium text-gray-700 lg:text-sm xl:text-base`}
}
`;

const Plan = styled.div`
  ${tw`w-full max-w-sm bg-gray-100 rounded-lg shadow-sm py-10 px-6 sm:px-10 lg:px-6 lg:py-10 xl:p-10 mx-3 flex flex-col justify-between mt-16 first:mt-0 lg:mt-0 shadow-raised`}
`;

const WhiteBackgroundOverlay = tw.div`absolute inset-x-0 bottom-0 h-1/6 lg:h-1/3 bg-white z-0`;

export default ({
  subheading = "",
  heading = "Features",
  description = "",
  plans = null,
}) => {
  const defaultPlans = [
    {
      name: "Easy Creation",
      imageSrc: CreateIconImage,
      description:
        "Quickly create an assessment as per NTA format with support for all question types.",
    },
    {
      name: "Easy Sharing",
      imageSrc: ShareIconImage,
      description:
        "Publish and share your assessments with your students through a secure link.",
    },
    {
      name: "Detailed Analytics",
      imageSrc: AnalyticsIconImage,
      description:
        "Provide every student with detailed insights and personalized recommendations to improve their scores.",
    },
  ];

  if (!plans) plans = defaultPlans;

  return (
    <Container id="Features" style={{ zIndex: 1 }}>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index}>
              <PlanHeader>
                <span className="imageContainer">
                  <img src={plan.imageSrc || defaultCardImage} alt="" />
                </span>
                <span className="nameAndFeaturedContainer">
                  <span className="name">{plan.name}</span>
                </span>
              </PlanHeader>
              <p className="description">{plan.description}</p>
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>
      <WhiteBackgroundOverlay />
    </Container>
  );
};
